import React from 'react';

function Logo(props) {
  return (
    <img
      alt="TeConsulto"
      src="/static/teconsulto_white_x150.png"
      style={{ maxHeight: 54, maxWidth: 200 }}
      {...props}
    />
  );
}

export default Logo;
