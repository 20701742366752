/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles,
  Typography
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import FaceIcon from '@material-ui/icons/Face';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { PieChart as PieChartIcon } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { getPhysician } from 'src/api/physicians';
import Logo from 'src/components/Logo';
import { APP_VERSION } from 'src/config';
import { getRoleName } from 'src/utils/role';
import NavItem from './NavItem';

const dashboardItem = {
  title: 'Tablero de control',
  icon: PieChartIcon,
  href: '/dashboard'
};

const adminNavConfig = [
  {
    subheader: 'Menú',
    items: [
      dashboardItem,
      {
        title: 'Consultas',
        href: '/appointments',
        icon: AssignmentIcon
      },
      {
        title: 'Disponibilidad',
        href: '/appointments/availability',
        icon: EventAvailableIcon
      }
    ]
  },
  {
    subheader: 'Administración',
    items: [
      {
        title: 'Médicos',
        href: '/physicians',
        icon: LocalHospitalIcon
      }
    ]
  }
];

const physicianNavConfig = [
  {
    subheader: 'Menú',
    items: [
      dashboardItem,
      {
        title: 'Consultas',
        href: '/appointments',
        icon: AssignmentIcon
      },
      {
        title: 'Disponibilidad',
        href: '/appointments/availability',
        icon: EventAvailableIcon
      }
    ]
  },
  {
    subheader: 'Administración',
    items: [
      {
        title: 'Pacientes',
        href: '/patients',
        icon: FaceIcon
      }
    ]
  }
];

const operatorNavConfig = [
  {
    subheader: 'Menú',
    items: [
      dashboardItem,
      {
        title: 'Consultas',
        href: '/appointments',
        icon: AssignmentIcon
      },
      {
        title: 'Disponibilidad',
        href: '/appointments/availability',
        icon: EventAvailableIcon
      }
    ]
  },
  {
    subheader: 'Administración',
    items: [
      {
        title: 'Pacientes',
        href: '/patients',
        icon: FaceIcon
      }
    ]
  }
];

const patientNavConfig = [
  {
    subheader: 'Menú',
    items: [
      dashboardItem,
      {
        title: 'Consultas',
        href: '/appointments',
        icon: AssignmentIcon
      }
    ]
  }
];

const analyticsNavConfig = [
  {
    subheader: 'Menú',
    items: [dashboardItem]
  }
];

const monitorNavConfig = [
  {
    subheader: 'Menú',
    items: [dashboardItem]
  },
  {
    subheader: 'Administración',
    items: [
      {
        title: 'Médicos',
        href: '/physicians',
        icon: LocalHospitalIcon
      }
    ]
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  helpText: {
    marginTop: '-1em'
  },
  helpButton: {
    marginTop: '1.5vmin',
    marginBottom: '1.5vmin',
    width: '90%'
  }
}));

function NavBar({ openMobile, onMobileClose, openDesktop }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);
  const [modalOpen, setModalOpen] = useState(false);
  const [navigationItems, setNavigationItems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [physician, setPhysician] = useState(null);

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const fetchPhysician = async (physicianId) => {
      try {
        const currentPhysician = await getPhysician(physicianId.toString());
        setPhysician(currentPhysician);
      } catch (e) {
        enqueueSnackbar(`Hubo un error cargando el médico.`, {
          variant: 'error'
        });
      }
    };

    if (user !== null && user.role === 'PHYSICIAN') {
      fetchPhysician(user.id);
    }
  }, [enqueueSnackbar, user]);

  useEffect(() => {
    if (user !== null) {
      switch (user.role) {
        case 'ADMIN':
          setNavigationItems(adminNavConfig);
          break;
        case 'PHYSICIAN':
          setNavigationItems(physicianNavConfig);
          break;
        case 'OPERATOR':
          setNavigationItems(operatorNavConfig);
          break;
        case 'PATIENT':
          setNavigationItems(patientNavConfig);
          break;
        case 'ANALYTICS':
          setNavigationItems(analyticsNavConfig);
          break;
        case 'MONITOR':
          setNavigationItems(monitorNavConfig);
          break;
        default:
      }
    }
  }, [user]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.logo ? user.logo : user.photo}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.first_name} ${user.last_name}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {getRoleName(user.role, physician)}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navigationItems.map((config) => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Grid
          alignItems="center"
          container
          direction="column"
          justify="center"
          style={{ marginTop: '5vh', marginBottom: '1vh' }}
        >
          <Typography color="textSecondary" className={classes.helpText}>
            ¿Tienes problemas técnicos?
          </Typography>
          <Button
            className={classes.helpButton}
            color="default"
            size="large"
            type="button"
            variant="contained"
            onClick={handleModalOpen}
          >
            Asistencia técnica
          </Button>
          <Dialog open={modalOpen}>
            <DialogTitle>Asistencia técnica</DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                Puedes contactarnos en cualquier momento a través de los
                siguientes medios para recibir asistencia técnica:
              </Typography>
              {/* <Typography>
                • Línea Sur Occidente (Cali): <b>(57) (2) 380 0848</b>
              </Typography>
              <Typography>
                • Línea Nacional (Bogotá): <b>(57) (1) 915 6583</b>
              </Typography> */}
              <Typography>
                • Información comercial: <b>info@teconsulto.co</b>
              </Typography>
              <Typography>
                • PQRS: <b>pqrs@teconsulto.co</b>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleModalClose} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Divider />
        <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
            textAlign="center"
          >
            <Typography variant="h6" color="textPrimary">
              {`v${APP_VERSION}`}
            </Typography>
            <Typography variant="h6" color="textPrimary">
              <Link
                component="a"
                color="secondary"
                href="/TyC"
                target="_blank"
                underline="always"
              >
                Términos y Condiciones
              </Link>
            </Typography>
            <Typography variant="h6" color="textPrimary">
              <Link
                component="a"
                color="secondary"
                href="/privacy-policy"
                target="_blank"
                underline="always"
              >
                Política de privacidad
              </Link>
            </Typography>
            <Typography variant="h6" color="textPrimary">
              Powered by{' '}
              <Link
                component="a"
                color="secondary"
                href="https://inappsis.com"
                target="_blank"
                underline="always"
              >
                Inappsis
              </Link>
            </Typography>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open={openDesktop}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  openDesktop: PropTypes.bool
};

export default NavBar;
