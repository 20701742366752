import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  logo: {
    maxHeight: 110,
    maxWidth: '90%',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 90
    }
  }
}));

function Logo(props) {
  const classes = useStyles();

  return (
    <img
      alt="TeConsulto"
      src="/static/teconsulto_x150.png"
      className={classes.logo}
      {...props}
    />
  );
}

export default Logo;
