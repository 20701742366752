import ky from 'ky';
import { API_PREFIX } from 'src/config';
import store from 'src/store';
import { logout } from 'src/store/account/actions';
import { getAuthToken } from 'src/utils/auth';

const api = ky.create({
  timeout: false,
  prefixUrl: API_PREFIX,
  hooks: {
    beforeRequest: [
      async (request) => {
        const token = getAuthToken();
        if (token) {
          request.headers.set('Authorization', `Bearer ${token}`);
        }
      }
    ],
    afterResponse: [
      async (request, options, response) => {
        if (
          !request.url.includes('login') &&
          // response.status === 400 ||
          (response.status === 401 || response.status === 403)
        ) {
          await store.dispatch(logout());
          window.location.href = '/';
        }
      }
    ]
  }
});

export default api;
