import client from 'src/utils/client';

type GetPhysicianType = (physicianId: string) => Promise<Physician>;
export const getPhysician: GetPhysicianType = (physicianId) =>
  client.get(`physicians/${physicianId}`).json();
export const getPhysicianSpecialties: GetPhysicianType = (physicianId) =>
  client.get(`physicians/${physicianId}/specialties`).json();

type GetPhysiciansType = (
  searchParams?: QueryParams
) => Promise<PaginationResponse<Physician>>;
export const getPhysicians: GetPhysiciansType = (searchParams) =>
  client.get(`physicians`, { searchParams }).json();

type CreatePhysicianType = (physician: BasePhysician) => Promise<Physician>;
export const createPhysician: CreatePhysicianType = (physician) =>
  client.post(`physicians`, { json: physician }).json();

type GetPublicInfoType = (slug: string) => Promise<PublicInfo>;
export const getPublicInfo: GetPublicInfoType = (slug) =>
  client.get(`physicians/${slug}/public`).json();

type DeletePhysicianType = (physicianId: number) => Promise<void>;
export const deletePhysician: DeletePhysicianType = (physicianId) =>
  client.delete(`physicians/${physicianId}`).json();

type UpdatePhysicianType = (
  physicianId: number,
  physician: Partial<Physician>
) => Promise<Physician>;
export const updatePhysician: UpdatePhysicianType = (physicianId, physician) =>
  client.put(`physicians/${physicianId}`, { json: physician }).json();

type UpdatePhysicianPasswordType = (password: string) => Promise<string>;
export const updatePhysicianPassword: UpdatePhysicianPasswordType = (
  password
) => client.put('login/update', { json: password }).json();

type GetPhysicianAvailabilitiesType = (
  physicianId: number
) => Promise<Availability[]>;
export const getPhysicianAvailabilities: GetPhysicianAvailabilitiesType = (
  physicianId
) => client.get(`physicians/${physicianId}/availabilities`).json();

type GetPhysicianTypeSlotType = (
  physicianId: number
) => Promise<PhysicianTimeSlot>;
export const getPhysicianTimeSlot: GetPhysicianTypeSlotType = (physicianId) =>
  client.get(`physicians/${physicianId}/time-slot`).json();

type GetPhysiciansAvailabilitiesType = () => Promise<Availability[]>;
export const getPhysiciansAvailabilities: GetPhysiciansAvailabilitiesType = () =>
  client.get(`physicians/availabilities`).json();

type GetLicenseByPhysicianId = (id: number) => Promise<License>;
export const getLicenseByPhysicianId: GetLicenseByPhysicianId = (id) =>
  client.get(`physicians/${id}/license`).json();

type GetPhysiciansExcel = (searchParams?: QueryParams) => Promise<Blob>;
export const getPhysiciansExcel: GetPhysiciansExcel = (searchParams) =>
  client.get(`physician/excel`, { searchParams }).blob();

type AddPhysicianLicenseRenew = (physicianId: number) => Promise<Physician>;
export const addPhysicianLicenseRenew: AddPhysicianLicenseRenew = (
  physicianId
) => client.post(`physicians/${physicianId}/license/renew`).json();

type ResetPhysicianPassword = (physicianId: number) => Promise<string>;
export const getPhysicianPasswordReset: ResetPhysicianPassword = (
  physicianId
) => client.get(`physicians/${physicianId}/reset`).json();

type AddPhysicianAcademicType = (
  academic: PhysicianAcademics
) => Promise<PhysicianAcademics>;
export const createPhysicianAcademic: AddPhysicianAcademicType = (academic) =>
  client.post(`physician/academic`, { json: academic }).json();

type GetPhysicianAcademicType = () => Promise<PhysicianAcademics[]>;
export const getPhysicianAcademic: GetPhysicianAcademicType = () =>
  client.get('physician/academic').json();

type GetPublicInfoAcademicType = (
  slug: string
) => Promise<PhysicianAcademics[]>;
export const getPublicInfoAcademics: GetPublicInfoAcademicType = (slug) =>
  client.get(`physicians/${slug}/public/academics`).json();

type GetPublicInfoLanguageType = (
  slug: string
) => Promise<PhysicianLanguages[]>;
export const getPublicInfoLanguages: GetPublicInfoLanguageType = (slug) =>
  client.get(`physicians/${slug}/public/languages`).json();

type GetPublicInfoLinkType = (slug: string) => Promise<PhysicianLinks[]>;
export const getPublicInfoLinks: GetPublicInfoLinkType = (slug) =>
  client.get(`physicians/${slug}/public/links`).json();

type GetPublicInfoMediaType = (slug: string) => Promise<PhysicianMedias[]>;
export const getPublicInfoMedias: GetPublicInfoMediaType = (slug) =>
  client.get(`physicians/${slug}/public/medias`).json();

type UpdatePhysicianAcademicType = (
  id: number,
  academic: PhysicianAcademics
) => Promise<PhysicianAcademics>;
export const updatePhysicianAcademic: UpdatePhysicianAcademicType = (
  id,
  academic
) => client.put(`physician/academic/${id}`, { json: academic }).json();

type DeletePhysicianAcademicType = (id: number) => Promise<String>;
export const deletePhysicianAcademic: DeletePhysicianAcademicType = (id) =>
  client.delete(`physician/academic/${id}`).json();

type AddPhysicianLanguageType = (
  language: PhysicianLanguages
) => Promise<PhysicianAcademics>;
export const createPhysicianLanguage: AddPhysicianLanguageType = (language) =>
  client.post(`physician/language`, { json: language }).json();

type GetPhysicianLanguageType = () => Promise<PhysicianLanguages[]>;
export const getPhysicianLanguage: GetPhysicianLanguageType = () =>
  client.get('physician/language').json();

type UpdatePhysicianLanguageType = (
  id: number,
  language: PhysicianLanguages
) => Promise<PhysicianLanguages>;
export const updatePhysicianLanguage: UpdatePhysicianLanguageType = (
  id,
  language
) => client.put(`physician/language/${id}`, { json: language }).json();

type DeletePhysicianLanguageType = (id: number) => Promise<String>;
export const deletePhysicianLanguage: DeletePhysicianLanguageType = (id) =>
  client.delete(`physician/language/${id}`).json();

type AddPhysicianLinkType = (link: PhysicianLinks) => Promise<PhysicianLinks>;
export const createPhysicianLink: AddPhysicianLinkType = (link) =>
  client.post(`physician/link`, { json: link }).json();

type GetPhysicianLinkType = () => Promise<PhysicianLinks[]>;
export const getPhysicianLink: GetPhysicianLinkType = () =>
  client.get('physician/link').json();

type UpdatePhysicianLinkType = (
  id: number,
  link: PhysicianLinks
) => Promise<PhysicianLinks>;
export const updatePhysicianLink: UpdatePhysicianLinkType = (id, link) =>
  client.put(`physician/link/${id}`, { json: link }).json();

type DeletePhysicianLinkType = (id: number) => Promise<String>;
export const deletePhysicianLink: DeletePhysicianLinkType = (id) =>
  client.delete(`physician/link/${id}`).json();

type AddPhysicianMediaType = (
  link: PhysicianMedias
) => Promise<PhysicianMedias>;
export const createPhysicianMedia: AddPhysicianMediaType = (media) =>
  client.post(`physician/media`, { json: media }).json();

type AddPhysicianMediaPhotoType = (
  formData: FormData
) => Promise<PhotoResponse>;
export const createPhysicianMediaPhoto: AddPhysicianMediaPhotoType = (
  formData
) => client.post('physician/media/photo', { body: formData }).json();

type GetPhysicianMediaType = () => Promise<PhysicianMedias[]>;
export const getPhysicianMedia: GetPhysicianMediaType = () =>
  client.get('physician/media').json();

type UpdatePhysicianMediaType = (
  id: number,
  media: PhysicianMedias
) => Promise<PhysicianMedias>;
export const updatePhysicianMedia: UpdatePhysicianMediaType = (id, media) =>
  client.put(`physician/media/${id}`, { json: media }).json();

type DeletePhysicianMediaType = (id: number) => Promise<String>;
export const deletePhysicianMedia: DeletePhysicianMediaType = (id) =>
  client.delete(`physician/media/${id}`).json();
