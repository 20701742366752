import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { CancelOutlined, VerifiedUserRounded } from '@material-ui/icons';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useCurrentUser } from 'src/store/account/selectors';

type Props = {
  open: boolean;
  onClose: () => void;
  onApply: () => void;
};

const useStyles = makeStyles(() => ({
  pos: {
    marginBottom: 12
  }
}));

const LicenseStatus: FC<Props> = ({ open, onClose, onApply }) => {
  const classes = useStyles();
  const [user, setUser] = useState<User | null>(null);
  const currentUser = useCurrentUser();
  const licenseExpiration = moment(user?.license_expiration);
  const todayNow = moment();

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  if (!user) {
    return null;
  }

  return (
    <Dialog maxWidth="lg" onClose={onClose} open={open}>
      <DialogTitle>Estado de la licencia</DialogTitle>
      <DialogContent
        dividers
        style={{ textAlign: 'center', justifyContent: 'center' }}
      >
        {licenseExpiration.diff(todayNow, 'seconds') < 0 ? (
          <CardContent>
            <Grid
              container
              style={{ textAlign: 'center', justifyContent: 'center' }}
            >
              <Grid item md={12} xs={12}>
                <CancelOutlined style={{ color: red[500], fontSize: 200 }} />
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography variant="h1" style={{ textTransform: 'uppercase' }}>
                  Licencia Vencida
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                {licenseExpiration.diff(todayNow, 'days') === 0 ? (
                  <Typography className={classes.pos} color="textSecondary">
                    ¡Tu licencia venció hoy!
                  </Typography>
                ) : (
                  <Typography className={classes.pos} color="textSecondary">
                    Tu licencia venció hace{' '}
                    {licenseExpiration.diff(todayNow, 'days')} días
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography variant="body2" component="p">
                  Su licencia se venció el {licenseExpiration.format('LL')}{' '}
                  <br /> se recomienda renovarla para seguir disfrutando del
                  servicio.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        ) : (
          <CardContent>
            <Grid
              container
              style={{ textAlign: 'center', justifyContent: 'center' }}
            >
              <Grid item md={12} xs={12}>
                <VerifiedUserRounded
                  style={{ color: green[500], fontSize: 200 }}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography variant="h1" style={{ textTransform: 'uppercase' }}>
                  Licencia Activa
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                {licenseExpiration.diff(todayNow, 'days') === 0 ? (
                  <Typography className={classes.pos} color="textSecondary">
                    Ya no le quedan días, su licencia vence{' '}
                    {licenseExpiration.fromNow()}
                  </Typography>
                ) : (
                  <Typography className={classes.pos} color="textSecondary">
                    Te quedan{' '}
                    <strong>
                      {licenseExpiration.diff(todayNow, 'days')} días
                    </strong>{' '}
                    de licencia
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography variant="body2" component="p">
                  Su licencia se vencerá el {licenseExpiration.format('LL')}
                  , se recomienda renovarla <br /> al menos un día antes para
                  seguir disfrutando del servicio.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LicenseStatus;
