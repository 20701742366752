// @ts-ignore
import _ from 'lodash';
import React, { createContext, FC, useEffect, useState } from 'react';
import { THEMES } from 'src/constants';
import { storeSettings } from 'src/utils/settings';

export interface SettingsContextInterface {
  settings?: any;
  saveSettings?: any;
}

const defaultSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.LIGHT,
  openNavBar: true
};

const SettingsContext = createContext<SettingsContextInterface>({});

type Props = {
  settings: any;
};

export const SettingsProvider: FC<Props> = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState(
    settings || defaultSettings
  );

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);
    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
