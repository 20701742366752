/** @jsxRuntime classic */
// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// issue: https://github.com/facebook/create-react-app/issues/9906
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'core-js/stable';
import { enableES5 } from 'immer';
import moment from 'moment';
import 'moment/locale/es';
import 'nprogress/nprogress.css';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import { Provider } from 'react-redux';
import { SettingsProvider } from 'src/context/SettingsContext';
import * as serviceWorker from 'src/serviceWorker';
import { configureStore } from 'src/store';
import { restoreSettings } from 'src/utils/settings';
import App from './App';

moment.locale('es');
enableES5();

const store = configureStore();
const settings = restoreSettings();

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider settings={settings}>
      <App />
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
