import JwtDecode from 'jwt-decode';

export const setAuthToken = (token: string) =>
  localStorage.setItem('token', token);

export const setUser = (user: User | null) =>
  localStorage.setItem('user', JSON.stringify(user));

export const setLicenseVerify = (expiration: String | null) =>
  localStorage.setItem('license_verify', JSON.stringify(expiration));

export const getAuthToken = () => localStorage.getItem('token');

export const getLicenseVerify = () => localStorage.getItem('license_verify');

export const getUser = () => localStorage.getItem('user');

export const deleteAuthToken = () => localStorage.removeItem('token');

export const isVerifyLicense = () => {
  const license = getLicenseVerify();
  if (license === null) {
    return false;
  }
  const lastVerify: string = license;
  return new Date(lastVerify) !== new Date();
};

export const isValidToken = () => {
  const token = getAuthToken();
  if (token === null) {
    return false;
  }
  const decoded = JwtDecode<User>(token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};
