export const getRoleName = (role: Role, physician: Physician | null = null) => {
  if (role === 'ADMIN') {
    return 'Administrador';
  }
  if (role === 'PHYSICIAN') {
    if (physician) {
      const odonologiaSpeciality = physician?.specialties.find((speciality) => {
        return speciality.name === 'Odontología';
      });
      if (odonologiaSpeciality) {
        return 'Odontólogo';
      }
      return 'Médico';
    }
    return 'Médico';
  }
  if (role === 'PATIENT') {
    return 'Paciente';
  }
  if (role === 'OPERATOR') {
    return 'Operador';
  }
  if (role === 'ANALYTICS') {
    return 'Analytics';
  }
  if (role === 'MONITOR') {
    return 'Monitor';
  }
  return '';
};
