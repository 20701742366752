import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppState } from 'src/store';
import { AccountState } from 'src/store/account/types';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { logout } from 'src/store/account/actions';
import moment from 'moment';
import { isVerifyLicense, setLicenseVerify } from 'src/utils/auth';
import LicenseStatus from '../views/pages/AccountView/License/LicenseStatus';

type Props = (allowedRoles?: Role[]) => FC;

const rolesGuard: Props = (allowedRoles) => ({ children }) => {
  const [modalLicense, setModelLicense] = useState(false);
  const [licenseValidation, setLicenseValidation] = useState(false);
  const account = useSelector<AppState, AccountState>((state) => state.account);
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const LicenseNotice = [30, 15, 7, 3, 2, 1, 0];

  const handleLicenseValidation = () => {
    setLicenseValidation(true);
    return null;
  };
  const handleModelLicenseOpen = () => {
    setModelLicense(true);
    return null;
  };
  const handleModelLicenseClose = () => {
    setLicenseVerify(Date.now().toString());
    setModelLicense(false);
    return null;
  };

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  if (account.user?.role === 'PHYSICIAN') {
    const licenseExpiration = moment(account.user.license_expiration);
    const todayNow = moment();
    if (licenseExpiration.diff(todayNow, 'seconds') < 0) {
      handleLogout();
      return null;
    }

    if (
      LicenseNotice.includes(licenseExpiration.diff(todayNow, 'days')) &&
      !isVerifyLicense() &&
      !licenseValidation
    ) {
      handleModelLicenseOpen();
      handleLicenseValidation();
      return null;
    }
  }

  if (
    allowedRoles &&
    allowedRoles.length > 0 &&
    account.user &&
    allowedRoles.includes(account.user.role)
  ) {
    return (
      <>
        {account.user?.role === 'PHYSICIAN' ? (
          <LicenseStatus
            open={modalLicense}
            onClose={handleModelLicenseClose}
            onApply={handleModelLicenseOpen}
          />
        ) : null}
        {children}
      </>
    );
  }

  return <Redirect to="/login" />;
};

export default rolesGuard;
