/* eslint-disable react/no-array-index-key */
import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import rolesGuard from './components/RolesGuard';
import DashboardLayout from './layouts/DashboardLayout';

type RoutesType = {
  exact?: boolean;
  path?: string;
  guard?: any;
  layout?: any;
  component?: any;
  routes?: RoutesType[];
};

const routesConfig: RoutesType[] = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/dashboard" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/forgot-password',
    component: lazy(() => import('./views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    path: '/p/reset/:token',
    component: lazy(() => import('./views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    path: '/privacy-policy',
    component: lazy(() => import('./views/terms/PrivacyPolicyView'))
  },
  {
    exact: true,
    path: '/TyC',
    component: lazy(() => import('./views/terms/ToSView'))
  },
  {
    path: '/dashboard',
    guard: rolesGuard([
      'ADMIN',
      'OPERATOR',
      'PHYSICIAN',
      'PATIENT',
      'ANALYTICS',
      'MONITOR'
    ]),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/dashboard',
        component: lazy(() => import('./views/pages/DashboardView'))
      }
    ]
  },
  {
    path: '/appointments',
    guard: rolesGuard(['ADMIN', 'OPERATOR', 'PHYSICIAN', 'PATIENT']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/appointments/availability',
        component: lazy(() => import('./views/pages/AvailabilityView'))
      },
      {
        exact: true,
        path: '/appointments',
        component: lazy(
          () => import('./views/pages/AppointmentsView/AppointmentsList')
        )
      },
      {
        exact: true,
        path: '/appointments/:id/:tab',
        component: lazy(
          () => import('./views/pages/AppointmentsView/AppointmentsDetail')
        )
      }
    ]
  },
  {
    path: '/patients',
    guard: rolesGuard(['ADMIN', 'OPERATOR', 'PHYSICIAN']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/patients',
        component: lazy(() => import('./views/pages/PatientsView/PatientsList'))
      },
      {
        exact: true,
        path: '/patients/:id/:tab',
        component: lazy(
          () => import('./views/pages/PatientsView/PatientsDetail')
        )
      }
    ]
  },
  {
    path: '/physicians',
    guard: rolesGuard(['ADMIN', 'MONITOR']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/physicians',
        component: lazy(
          () => import('./views/pages/PhysiciansView/PhysiciansList')
        )
      },
      {
        exact: true,
        path: '/physicians/:id/:tab',
        component: lazy(
          () => import('./views/pages/PhysiciansView/PhysiciansDetail')
        )
      }
    ]
  },
  {
    path: '/operators',
    guard: rolesGuard(['ADMIN']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/operators',
        component: lazy(
          () => import('./views/pages/OperatorsView/OperatorsList')
        )
      },
      {
        exact: true,
        path: '/operators/:id/:tab',
        component: lazy(
          () => import('./views/pages/OperatorsView/OperatorsDetail')
        )
      }
    ]
  },
  {
    path: '/account',
    guard: rolesGuard(['PHYSICIAN']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/account',
        component: lazy(() => import('./views/pages/AccountView'))
      }
    ]
  },
  {
    exact: true,
    path: '/v/:sessionId',
    component: lazy(() => import('./views/lobby'))
  },
  {
    exact: true,
    path: '/checkout/copago/:sessionId',
    component: lazy(() => import('./views/checkout/CopagoView'))
  },
  {
    exact: true,
    path: '/checkout/consulta/:sessionId',
    component: lazy(() => import('./views/checkout/AppointmentView'))
  },
  {
    exact: true,
    path: '/p/appointments/:sessionId/cancel',
    component: lazy(() => import('./views/appointments'))
  },
  {
    exact: true,
    path: '/agendar-consulta/:slug',
    component: lazy(() => import('./views/selfservice/SelfScheduleView'))
  },
  {
    exact: true,
    path: '/perfil/:slug',
    component: lazy(() => import('./views/physicianspage'))
  },
  {
    path: '*',
    guard: GuestGuard,
    routes: [
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes: RoutesType[]) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
