import client from 'src/utils/client';
import { AccountState } from '../store/account/types';

type LoginType = (username: string, password: string) => Promise<LoginResponse>;
type UpdateUserType = (update: Partial<AccountState['user']>) => Promise<User>;
type UpdateStringType = (update: string) => Promise<any>;
type UpdateFormDataType = (update: FormData) => Promise<PhotoResponse>;

export const loginUser: LoginType = (username, password) =>
  client
    .post('login', {
      json: {
        email: username,
        password
      }
    })
    .json();

export const updateProfileRequest: UpdateUserType = (update) =>
  client
    .patch('/auth/me', {
      json: {
        update
      }
    })
    .json();

export const updatePasswordRequest: UpdateStringType = (update) =>
  client
    .post('/auth/me/set-password', {
      json: {
        update
      }
    })
    .json();

export const updatePhotoRequest: UpdateFormDataType = (update) =>
  client
    .post('/auth/set-photo', {
      body: update
    })
    .json();

type CreatePhotoType = (formData: FormData) => Promise<Photo>;
export const createPhoto: CreatePhotoType = (formData) =>
  client.post('photo', { body: formData }).json();

type CreateLogoType = (formData: FormData) => Promise<Logo>;
export const createLogo: CreateLogoType = (formData) =>
  client.post('physicians/logo', { body: formData }).json();
