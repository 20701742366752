import jwtDecode from 'jwt-decode';
import {
  createLogo,
  createPhoto,
  loginUser,
  updatePasswordRequest,
  updateProfileRequest
} from 'src/api/auth';
import {
  deleteAuthToken,
  getAuthToken,
  setAuthToken,
  setUser
} from 'src/utils/auth';
import { ThunkResult } from '..';
import {
  AccountState,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_LOGO,
  UPDATE_PASSWORD,
  UPDATE_PHOTO,
  UPDATE_PROFILE
} from './types';

export function login(
  username: string,
  password: string
): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const userToken = await loginUser(username, password);
      setAuthToken(userToken.token);
      const user = jwtDecode<User>(userToken.token);
      setUser(user);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user: AccountState['user']): ThunkResult<void> {
  return (dispatch) =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user
      }
    });
}

export function logout(): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    deleteAuthToken();
    dispatch({
      type: LOGOUT
    });
  };
}

export function updateProfile(
  update: Partial<AccountState['user']>
): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    const user = await updateProfileRequest(update);
    dispatch({
      type: UPDATE_PROFILE,
      payload: {
        user
      }
    });
  };
}

export function updatePassword(update: string): ThunkResult<void> {
  return async (dispatch) => {
    await updatePasswordRequest(update);
    dispatch({
      type: UPDATE_PASSWORD
    });
  };
}

export function updatePhoto(obj: User, photo: string): ThunkResult<void> {
  return async (dispatch) => {
    const authToken = getAuthToken();
    let user = null;
    if (authToken) {
      user = jwtDecode<User>(authToken);
      user.logo = obj.logo;
      user.photo = photo;
    }
    setUser(user);
    dispatch({
      type: UPDATE_PHOTO,
      payload: {
        user
      }
    });
  };
}

export function updateLogo(obj: User, logo: string): ThunkResult<void> {
  return async (dispatch) => {
    const authToken = getAuthToken();
    let user = null;
    if (authToken) {
      user = jwtDecode<User>(authToken);
      user.photo = obj.photo;
      user.logo = logo;
    }
    setUser(user);
    dispatch({
      type: UPDATE_PHOTO,
      payload: {
        user
      }
    });
  };
}
