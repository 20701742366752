import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '..';
import { AccountState } from './types';

export const useCurrentUser = () => {
  const accountState = useSelector<AppState, AccountState>(
    (state) => state.account
  );

  return useMemo(() => accountState.user || null, [accountState.user]);
};
